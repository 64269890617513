import React from 'react'
import { Routers } from 'app/Routers'

export const Layout = () => {
	return (
		<div style={{ marginTop: '56px' }}>
			{/*<Header />*/}
			<Routers />
			{/*<Footer />*/}
		</div>
	)
}
